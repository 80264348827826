import { FaMountain } from "react-icons/fa";
import { defineField, defineType } from "sanity";
import allPageBuilderSections, {
  pageBuilderOptions
} from "../../common/allPageBuilderSections";

export default defineType({
  name: "homepage",
  title: "Homepage",
  icon: FaMountain,
  type: "document",
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "urgent-message",
      title: "Urgent Message",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "fares",
      title: "Fares",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "offers",
      title: "Offers",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "ships",
      title: "Ships",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "destinations",
      title: "Destinations",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
  ],
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "heroMedia",
      title: "Hero Media",
      type: "heroMediaContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "enableUrgentMessageSlide",
      title: "Enable Urgent Message Slide",
      description:
        "Use only for real urgent message (if enabled the auto slide of the hero banners will be disabled)",
      type: "boolean",
      fieldset: "urgent-message"
    },
    {
      name: "urgentMessageSlide",
      title: "Urgent Message Slide",
      description: "Use only for real urgent message",
      type: "multilingualBlockContent",
      fieldset: "urgent-message",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    defineField({
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    }),
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Homepage"
      };
    }
  }
});
