import { RiCarouselView } from "react-icons/ri";
import { defineType } from "sanity";

export default defineType({
  title: "Emotional Content Slider",
  name: "emotionalContentSlider",
  type: "object",
  icon: RiCarouselView,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "items",
      title: "Contents",
      type: "array",
      of: [{ type: "emotionalSlideContent" }, { type: "emotionalSlidePromoContent" }]
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      items: "items"
    },
    prepare({ title, items }) {
      return {
        title: title || "([Emotional Content] Slider)",
        subtitle: `${items.length} slides`
      };
    }
  }
});
