import { defineType } from "sanity";
import {
  airNonAirFilter,
  locationFilter,
} from "../base";
import { multilingualStringPreview } from "../base/multilingualString";

export default defineType({
  name: "emotionalSlideContent",
  type: "object",
  title: "Slide content",
  fields: [
    {
      name: "image",
      type: "imageWithAlt",
      options: {
        hotspot: true,
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    },
    {
      name: "customKey",
      title: "Custom Key",
      description: "Used to identify the slide in the code",
      type: "string"
    }
  ],
  preview: {
    select: {
      imageUrl: "image",
      title: "title"
    },
    prepare({ title, imageUrl }) {
      return {
        //@ts-ignore
        title: multilingualStringPreview(title) || "Emotional Slide Content",
        media: imageUrl
      };
    }
  }
});
