import { defineType } from "sanity";
import { filterableMultilingualString } from "../base";

export default defineType({
  name: "heroMediaContent",
  type: "object",
  title: "Hero Media Content",
  fields: [
    {
      name: "mediaType",
      title: "Media Type",
      type: "string",
      options: {
        list: ["video", "image"]
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "video",
      title: "Video File",
      type: "video",
      hidden: ({ parent }) => parent?.mediaType !== "video"
    },
    {
      name: "mobileVideo",
      title: "Video Mobile File",
      description: "if empty, fallback to video file",
      type: "widen.video",
      hidden: ({ parent }) => parent?.mediaType !== "video"
    },
    {
      name: "image",
      type: "imageWithAlt",
      hidden: ({ parent }) => parent?.mediaType !== "image",
      options: {
        hotspot: true
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualBlockContent"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "promo",
      title: "Promo",
      type: "reference",
      to: [
        {
          type: "exclusiveOfferPerPromo"
        }
      ]
    },
    {
      name: "showCountdown",
      title: "Show Countdown",
      type: "boolean",
      description: "Show countdown timer",
      hidden: ({ parent }) => !parent?.promo
    },
    {
      name: "countdownLabel",
      title: "Countdown label",
      type: "multilingualString",
      hidden: ({ parent }) => !parent?.promo || !parent?.showCountdown
    }

    // {
    //   name: "locationFilters",
    //   title: "Location Filters",
    //   type: "array",
    //   of: [
    //     {
    //       type: locationFilter.name
    //     },
    //     {
    //       type: airNonAirFilter.name
    //     }
    //   ]
    // },
    // {
    //   name: "customKey",
    //   title: "Custom Key",
    //   description: "Used to identify the slide in the code",
    //   type: "string"
    // }
  ],
  preview: {
    select: {
      imageUrl: "image",
      title: "title"
    },
    prepare({ title, imageUrl }) {
      return {
        //@ts-ignore
        title: filterableMultilingualString.toString((title || [])[0]),
        media: imageUrl
      };
    }
  }
});
